<template>
  <div>
    <section class="introduction">
      <div class="introduction__content">
        <h1 class="introduction__title">Cennik</h1>
        <p class="introduction__text">
          Chcesz zapisać się na nasze zajęcia i jesteś już zdecydowany? Sprawdź nasz cennik i grafik zajęć, aby wybrać,
          które będą Ci najlepiej pasować
        </p>
        <div class="btn-wrapper">
          <base-button
            classes="light-btn"
            type="external-link"
            to="https://wod.guru/kup-karnet/crossfitblackstar"
          >Cennik
          </base-button>
          <base-button
            classes="light-btn"
            type="external-link"
            to="https://wod.guru/grafik/crossfitblackstar"
          >Grafik Zajęć
          </base-button>
        </div>
      </div>
      <img class="introduction__img" src="../assets/img/cennik.jpg" />
    </section>
    <section class="regulations">
      <h2>Regulamin Płatności</h2>
      <base-button
        classes="red-btn justify-self-start pdf-btn"
        type="external-link"
        to="/regulaminplatnosci-blackstar.pdf"
      >Pobierz PDF
      </base-button>
      <div>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p class="s1" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Słownik pojęć:</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Regulamin <a
          href="http://WWW.CROSSFITBLACKSTAR.COM.PL/" class="a" target="_blank">– niniejszy regulamin sprzedaży karnetów
          do
          Klubu za pośrednictwem strony Internetowej WWW.CROSSFITBLACKSTAR.COM.PL</a>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Klub <span class="p">– STUDIO TRENINGOWE BLACK STAR Komandorska 18, 53-343 Wrocław, Polska</span>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Firma <span class="p">– STUDIO TRENINGOWE BLACK STAR Sp. zo.o. Komandorska 18, 53-343 Wrocław, Polska</span>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><b>Dane rejestrowe </b>– NIP: 8992899165,
          REGON:<span
            style=" color: #333;">388987416 </span>,</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Strona internetowa <a
          href="http://WWW.CROSSFITBLACKSTAR.COM.PL/" class="a" target="_blank">– strona internetowa WWW.CROSSFITBLACKSTAR.COM.PL</a></h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Karnety <span class="p">– karnety do Klubu przedstawione w ofercie klubu dostępne w systemie sprzedaży on-line za pośrednictwem strony internetowej.</span>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Kupujący <span class="p">– osoba , która dokonała zakupu on-line.</span>
        </h3>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
      </div>
      <ol id="l1">
        <li data-list-text="1."><p style="text-indent: -18pt;text-align: left;">Sprzedaż karnetów
          prowadzi
          Firma.</p></li>
        <li data-list-text="2."><p style="text-indent: -18pt;text-align: left;">Umowa sprzedaży
          pomiędzy
          Kupującym a Firmą zostaje zawarta w przypadku spełnienia wymaganych procedur zakupu Karnetów:</p>
          <ul id="l2">
            <li data-list-text="o"><p style="text-indent: -18pt;text-align: left;">poprzez
              wypełnienie formularza, w którym należy wpisać: imię i nazwisko, pesel, adres e-mail, numer telefonu, adres zamieszkania,
            </p></li>
            <li data-list-text="o"><p style="text-indent: -18pt;text-align: left;">złożenia przez
              Kupującego zamówienia on-line poprzez wypełnienie formularza zawartego na stronie internetowej,</p></li>
            <li data-list-text="o"><p style="text-indent: -18pt;text-align: left;">uiszczenia przez
              Kupującego zapłaty za zamówienie przelewem bankowym on-line za pomocą systemu Przelewy24.</p></li>
            <li data-list-text="o"><p style="text-indent: -18pt;text-align: left;">otrzymania przez
              Kupującego potwierdzenia realizacji transakcji na wskazany przez Kupującego adres e-mail, przesłanego z
              systemu
              Przelewy24.</p></li>
          </ul>
        </li>
        <li data-list-text="3."><p style="text-indent: -18pt;text-align: left;">Przed skorzystaniem z
          procesu zakupu Karnetów, Kupujący powinien zapoznać się z Regulaminem znajdującym się w sekcji Regulaminy .
          Zakup
          przez Internet jest równoznaczne z akceptacją jego treści.</p></li>
        <li data-list-text="4."><p style="text-indent: -18pt;line-height: 13pt;text-align: left;">
          Kupujący
          ma możliwość płatności online przez system Przelewy24.</p></li>
        <li data-list-text="5."><p style="text-indent: -18pt;text-align: left;">Klub nie ponosi
          odpowiedzialności za wszelkie konsekwencje wynikające z nieprawidłowości w funkcjonowaniu systemu płatniczego
          Przelewy24.</p></li>
        <li data-list-text="6."><p style="text-indent: -18pt;text-align: left;">Zgodnie z
          rozporządzeniem
          Ministra Finansów z dnia 4 listopada 2014 w sprawie zwolnień z obowiązku prowadzenia ewidencji przy
          zastosowaniu kas
          rejestrujących świadczenie usług na rzecz osób fizycznych nieprowadzących działalności gospodarczej oraz
          rolników
          ryczałtowych ,jeśli świadczący usługę otrzyma w całości zapłatę za wykonaną czynność za pośrednictwem poczty
          ,banku
          lub spółdzielczej kasy oszczędnościowo-kredytowej, a z ewidencji i dowodów dokumentujących zapłatę
          jednoznacznie
          wynika , jakiej konkretnie czynności dotyczyła sprzedaż usług w zakresie zamówienia i płatności drogą
          internetową
          nie podlega ewidencji w kasie fiskalnej, w związku z czym Kupujący nie otrzymuje paragonu fiskalnego.</p></li>
        <li data-list-text="7."><p style="text-indent: -18pt;line-height: 13pt;text-align: left;">
          Ceny
          Karnetów zawierają podatek VAT.</p></li>
        <li data-list-text="8."><p style="text-indent: -18pt;text-align: left;">Zakupiony Karnet
          zostanie
          ustawiony na koncie klienta zaraz po zaksięgowaniu wpłaty przez Przelewy24.</p></li>
        <li data-list-text="9."><p style="text-indent: -18pt;text-align: left;">Warunkiem ustawienia
          karnetu na koncie Klienta jest dokonanie płatności (Kupuję z obowiązkiem zapłaty Przelewy24). Płatność uznaje
          się za
          dokonaną z momentem otrzymania</p>
          <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><a name="bookmark1">przez
            Firmę
            informacji o ustanowieniu nowej transakcji w serwisie Przelewy24 i wpływie środków na rachunek
            Przelewy24.</a></p>
        </li>
        <li data-list-text="10."><p style="text-indent: -18pt;text-align: left;">W przypadku, gdy
          Kupujący
          dokonuje zakupu po raz pierwszy zobowiązuje się do wyrobienia karty członkowskiej w siedzibie klubu. Po
          zaewidencjonowaniu wpłaty zostanie wydrukowana karta z wybraną przez Kupującego opcją Karnetu. Kartę
          Członkowską
          odebrać można w recepcji Klubu.</p></li>
        <li data-list-text="11."><p style="text-indent: -18pt;text-align: left;">W celu otrzymania
          faktury
          VAT Kupujący powinien zgłosić ten fakt w recepcji obiektu.</p>
          <p style="text-indent: 0pt;text-align: left;"><a href="mailto:INFO@CFBLACKSTAR.COM"
                                                                              class="a"
                                                                              target="_blank">Zgłoszenie można przesłać
            za
            pomocą poczty elektronicznej na adres INFO@CFBLACKSTAR.CO</a>M podając dane do faktury VAT, lub zgłosić
            osobiście
            w recepcji klubu. Faktura zostanie</p>
          <p style="text-indent: 0pt;line-height: 13pt;text-align: left;">przygotowana i gotowa do
            odbioru
            w recepcji klubu.</p></li>
        <li data-list-text="12."><p style="text-indent: -18pt;text-align: left;">Za pośrednictwem
          strony
          internetowej można kupić jedynie Karnety w cenach podanych na stronie internetowej Klubu.</p></li>
        <li data-list-text="13."><p style="text-indent: -18pt;text-align: left;">Klub zastrzega sobie
          możliwość zakończenia sprzedaży przez stronę internetową w każdej chwili, bez podawania przyczyn.</p></li>
        <li data-list-text="14."><p style="text-indent: -18pt;text-align: left;">Klub nie ponosi
          odpowiedzialności za skutki podania przez Kupującego nieprawdziwych lub błędnych danych w formularzu
          zamówienia.</p>
        </li>
        <li data-list-text="15."><p style="text-indent: -18pt;text-align: left;">Karnety nie mogą być
          wymienione na inne opcje niż zostały zakupione.</p></li>
        <li data-list-text="16."><p style="text-indent: -18pt;text-align: left;"><a
          href="mailto:INFO@CFBLACKSTAR.COM" class="a" target="_blank">Wszelkie reklamacje należy kierować do Firmy.
          Reklamacje można zgłaszać za pośrednictwem poczty elektronicznej na adres </a><a
          href="mailto:INFO@CFBLACKSTAR.COM"
          target="_blank">INFO@CFBLACKSTAR.COM</a>
        </p></li>
        <li data-list-text="17."><p style="text-indent: -18pt;line-height: 13pt;text-align: left;">
          Zgłoszenie reklamacji powinno zawierać:</p>
          <ul id="l3">
            <li data-list-text="o"><p style="text-indent: -18pt;line-height: 14pt;text-align: left;">
              dane
              Kupującego zgodne z danymi podanymi przy dokonywaniu zakupu karnetu,</p></li>
            <li data-list-text="o"><p style="text-indent: -18pt;line-height: 14pt;text-align: left;">
              datę
              dokonania transakcji zakupu i numer transakcji,</p></li>
            <li data-list-text="o"><p style="text-indent: -18pt;line-height: 14pt;text-align: left;">
              opis
              przedmiotu reklamacji.</p></li>
          </ul>
        </li>
        <li data-list-text="18."><p style="text-indent: -18pt;line-height: 13pt;text-align: left;">
          Reklamacje zostaną rozpatrzone nie później niż w terminie 14 dni od momentu jej zgłoszenia.</p></li>
        <li data-list-text="19."><p style="text-indent: -18pt;text-align: left;">Informacja o
          przyjęciu lub
          odrzuceniu reklamacji zostanie przesłana Kupującemu na adres podany w poczcie elektronicznej.</p></li>
        <li data-list-text="20."><p style="text-indent: -18pt;text-align: left;">Kupujący wyraża
          zgodę na
          przechowywanie i przetwarzanie przez Klub danych osobowych Kupującego w celu realizacji zamówienia, kontaktu z
          Kupującym oraz wszelkich działań niezbędnych do funkcjonowania systemu sprzedaży Karnetów, a także w celach
          przekazywania informacji o promocjach oferowanych przez Klub.</p></li>
        <li data-list-text="21."><p style="text-indent: -18pt;text-align: left;">Kupującemu
          przysługuje
          prawo dostępu do treści swoich danych oraz ich poprawiania, a także do kontroli przetwarzania danych, które je
          dotyczą na zasadach określonych w art. 32 ust. 1 ustawy z dnia 29 sierpnia 1997 roku o ochronie danych
          osobowych (
          tekst jednolity Dz. U. 2002 nr 101, poz.926 z późniejszymi zmianami) oraz w Ustawie z dnia 18 lipca 2002 r. o
          świadczeniu usług drogą elektroniczną ( Dz. U. z 2002 r., nr 144, poz. 1204).</p></li>
        <li data-list-text="22."><p style="text-indent: -18pt;text-align: left;">Kupujący
          udostępniający
          swoje dane osobowe ma prawo do dostępu do nich, jak również do ich poprawiania i uaktualniania w recepcji
          Klubu.</p>
        </li>
        <li data-list-text="23."><p style="text-indent: -18pt;line-height: 13pt;text-align: left;">
          Klub
          świadczy usługi sprzedaży Karnetów w sposób zgodny z postanowieniami Regulaminu.</p></li>
        <li data-list-text="24."><p style="text-indent: -18pt;text-align: left;">O planowanej zmianie
          Regulaminu Firma poinformuje z 14-dniowym wyprzedzeniem, zamieszczając na stronie w miejscu widocznym
          dla
          Kupującego przed</p>
          <p style="text-indent: 0pt;text-align: justify;">złożeniem zamówienia, przy czym do
            zamówień
            złożonych przed dniem wejścia w życie zmian, zastosowania mają postanowienia dotychczasowe, chyba że,
            Kupujący
            wyrazi na piśmie zgodę na zastosowanie wobec niego nowych postanowień.</p></li>
        <li data-list-text="25."><p style="text-indent: -18pt;text-align: justify;"><a
          href="http://WWW.CROSSFITBLACKSTAR.COM.PL/" class="a" target="_blank">Wszystkie informacje na temat cennika
          oraz
          dane kontaktowe opublikowane są na stronie </a><a href="http://WWW.CROSSFITBLACKSTAR.COM.PL/" target="_blank">WWW.CROSSFITBLACKSTAR.COM.PL</a>
        </p>
          <p style="text-indent: 2pt;text-align: left;">Klub zastrzega sobie prawo do zmian w ofercie
            i
            cenniku świadczonych usług.</p></li>
        <li data-list-text="26."><p style="text-indent: -18pt;text-align: left;">Niniejszy Regulamin
          sprzedaży on-line dostępny jest na stronie internetowej oraz w recepcji Klubu.</p></li>
      </ol>
    </section>
  </div>
</template>

<script>
export default {
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }

    this.$store.state.IsFiframeTransform = false;
    next();
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/price-list.scss";
</style>
